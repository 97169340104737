import React from "react"
import { Container } from "react-bootstrap"
import Slider from "react-slick"

// import * as styles from "./ContactSales.module.scss"

import upWork from "../../images/accolades/upwork.png"
import goodFirms from "../../images/cta/Top_Web_Developers.png"
import appFutura from "../../images/accolades/appfutura.png"
import develop4u from "../../images/accolades/develop4u.png"
import topDevelopers from "../../images/accolades/top-developers.png"
import itFirms from "../../images/accolades/itfirms.png"
import glassDoor from "../../images/cta/Glassdoor.png"
// import topDevelopers1 from '../../images/cta/Top_Developers.png';
import agencyVista from "../../images/cta/agency_vista.png"
import topFirms from "../../images/accolades/top-firms.png"
import clutch2021 from "../../images/accolades/clutch-2021.png"
import wadline from "../../images/accolades/wadline.png"
import designrushNew from "../../images/accolades/designrush-new.png"
import techreviewer from "../../images/accolades/techreviewer.png"
import upCity from "../../images/cta/upCity.png"
import selectedFirms from "../../images/cta/selectedfirms.png"
import mobileApp from "../../images/cta/mobiledaily.png"

const TopSDCompany = ({ bgColor }) => {
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section
      //   className={styles.section}
      style={{ backgroundColor: `${bgColor}` }}
    >
      <h3 className="main-heading-36 text-center pb-5">
        Featured as top software development consulting company
      </h3>
      <Container>
        <div>
          <Slider {...settings} className="pl-3 pr-0 pl-md-4 pr-md-0">
            <div>
              <a
                href="https://www.upwork.com/ag/invozone/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={upWork}
                  alt="Upwork Top Rated"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.goodfirms.co/company/invozone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={goodFirms}
                  alt="GoodFirms - Top Software Development Company"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.appfutura.com/companies/invozone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appFutura}
                  alt="AppFutura"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://develop4u.co/company/invozone"
                target="_blank"
                rel="noreferrernoopener "
              >
                <img
                  src={develop4u}
                  alt="Developers 4 U"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.topdevelopers.co/profile/invozone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={topDevelopers}
                  alt="Top Developers"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.itfirms.co/top-mobile-app-development-companies-in-canada/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={itFirms}
                  alt="ITFirms"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>

            <div>
              <a
                href="https://www.glassdoor.ca/Overview/Working-at-InvoZone-EI_IE3297931.11,19.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={glassDoor}
                  alt="Software Devleopment company"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>

            {/* <div >
                            <a
                                href="https://topdevelopers.biz/topdeveloper/custom-software-development-developers-companies-usa/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >

                                <img
                                    src={topDevelopers1}
                                    alt="Top Developers"
                                    placeholder="blurred"
                                    loading='lazy'
                                    width={125}
                                    height={125}
                                />
                            </a>
                        </div> */}

            <div>
              <a
                href="https://agencyvista.com/agency/invozone/marketing-agency-toronto-ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={agencyVista}
                  alt="review"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>

            <div>
              <a
                href="https://topfirms.co/companies/software-development/world"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={topFirms}
                  alt="Top Firms"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://clutch.co/profile/invozone"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  src={clutch2021}
                  alt="Clutch 2021 - Top Web Developers in Canada"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://wadline.com/invozone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={wadline}
                  alt="wadline"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.designrush.com/agency/ar-vr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={designrushNew}
                  alt="designrush"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://techreviewer.co/companies/invozone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={techreviewer}
                  alt="techreviewer"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://upcity.com/profiles/invozone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={upCity}
                  alt="techreviewer"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://selectedfirms.co/companies/ecommerce-development/usa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={selectedFirms}
                  alt="eCommerce site"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.mobileappdaily.com/reports/fastest-growing-app-development-companies"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mobileApp}
                  alt="mobile app"
                  placeholder="blurred"
                  loading="lazy"
                  width={125}
                  height={125}
                />
              </a>
            </div>
          </Slider>
        </div>
      </Container>
    </section>
  )
}

export default TopSDCompany
