import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Banner.module.scss";
import { Map } from "../common";
const ContactForm = React.lazy(() => import("./ContactForm"));
const isSSR = typeof window === "undefined";

const Banner = () => {
  const location = {
    address: "Tv2uasia",
    lat: 3.0976558,
    lng: 101.7016444,
  };
  return (
    <>
      <section className={styles.careers}>
        <Container>
          <Row className=" justify-content-center">
            <Col lg={6} className="pl-2 pl-lg-5 pt-lg-5 mt-lg-4">
              <h1 className="main-banner-heading-55">
                <span className="text-primary">Let’s build </span>something
                awesome together.
              </h1>
              <div>
                <h4 className="font-weight-normal pb-1">
                  {" "}
                  We’d love to discuss your ideas and look forward to bring them
                  to life!
                </h4>
              </div>
              <Map location={location} zoomLevel={20} />
            </Col>
            <Col lg={6} className="px-2 mt-5 mt-lg-0 d-flex">
              <div className={styles.illustration}>
                <StaticImage
                  src="../../images/illustrations/contact_us_Illustration.svg"
                  alt="where we are located"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              <div className={styles.card}>
                {!isSSR && (
                  <React.Suspense
                    fallback={
                      <div className="text-center">
                        <Spinner
                          variant="light"
                          size="sm"
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                        />
                      </div>
                    }
                  >
                    <ContactForm />
                  </React.Suspense>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Banner;
