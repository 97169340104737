import React, { useEffect } from "react";
import { graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image"

import MainLayout from "../layouts/MainLayout";
import { Map, SEO } from "../components/common";
import {
  Banner,
  ReliableSDS,
  TopSDCompany,
  Offices,
} from "../components/contact-sections";

const ContactUsPage = ({ data }) => {
  // const { seo } = data?.wpPage;
  // const { reliableSds } = data?.content?.commonFields;
  const seo = {
    title: "Contact Us - Tv2u",
    metaDesc:
      "Global Software Development Outsourcing Company. Hire Tv2u offshore developers team and tech experts for web and product development.",
  };
  useEffect(() => {
    localStorage.setItem("popup", false);
  }, []);

  const reliableSds = [
    {
      title: "Successful projects",
      value: "300+",
    },
    {
      title: "Customer satisfaction rate",
      value: "97%",
    },
    {
      title: "Developers and engineers",
      value: "500+",
    },
    {
      title: "Years of experience",
      value: "8+",
    },
  ];

  return (
    <MainLayout bgChanged={false}>
      <SEO title={seo.title} description={seo.metaDesc} />

      <Banner />
      <ReliableSDS reliableSds={reliableSds} />
      <TopSDCompany />

      {/* <Offices /> */}
    </MainLayout>
  );
};

// export const query = graphql`
//   query {
//     wpPage(slug: { eq: "contact-us" }) {
//       seo {
//         title
//         metaDesc
//       }
//     }
//     content: wpPage(slug: { eq: "acf-common" }) {
//       commonFields {
//         reliableSds {
//           title
//           value
//         }
//       }
//     }
//   }
// `;

export default ContactUsPage;
