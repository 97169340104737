import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import * as styles from "./ReliableSDS.module.scss"

const ReliableSDS = ({ reliableSds = [] }) => {

    return (
        <section className={styles.section}>
            <Container >
                <h2 className="pb-5 pt-5 main-heading-36">
                    Dedicated to Provide Reliable Software Development Services
                </h2>
                <Row className='px-md-5 justify-content-center'>
                    {
                        reliableSds?.map(item => (<Col sm={6} md={6} lg={6} xl={3} className={styles.colContainer} key={item?.title}>
                            <div >
                                <h2 className='main-banner-heading-55 text-primary'>{item?.value}</h2>
                                <p className='text-uppercase'>{item?.title}</p>
                            </div>
                        </Col>))
                    }
                </Row>
            </Container>
        </section>
    )
}


export default ReliableSDS;




